/* eslint-disable react/require-default-props */
import React from 'react';
import styled from 'styled-components';
import { Image, Button, Icon } from 'semantic-ui-react';
import cx from 'classnames';
import _ from 'lodash';

import { getOneOf } from '../../lib/utils';
import { useMetaOverride } from '../../lib/hoc/useMeta';
import ProfileNewsCard from './ProfileNewsCard';
import PageContext from '../PageContext';
import ContactForm from './ContactForm';


const Thumbnail = ({ item }) => {
  const thumbnail = getOneOf(item, ['thumbnail.publicURL', 'thumbnail']);
  const thumbnailUrl = _.get(item, 'thumbnailUrl');
  const thumbnailAlt = _.get(item, 'thumbnailAlt');
  const thumbnailTarget = _.get(item, 'thumbnailTarget');
  const thumbProps = {
    ...(thumbnailUrl ? { as: 'a', href: thumbnailUrl } : {}),
    ...(thumbnailTarget ? { target: thumbnailTarget } : {}),
    ...(thumbnailAlt ? { alt: thumbnailAlt } : {}),
  }
  const email = _.get(item, 'email');
  return (
    <div className="thumbnail">
      <Image
        src={thumbnail}
        {...thumbProps}
      />
      <div className="name">{`${_.get(item, 'name')}`}</div>
      {
        !!email &&
        <ContactForm />
      }
    </div>
  );
};

export const ProfileNews = props => {
  const pageDataContext = React.useContext(PageContext);
  const { metaData } = props;

  const thumbnailUrl = _.get(pageDataContext, 'data.organization.data.Logo.0.url');
  const email = _.get(pageDataContext, 'data.organization.data.Email');
  const collegeName = _.get(pageDataContext, 'data.organization.data.Organization_Name');
  const newsNodes = _.get(pageDataContext, 'data.news.nodes');
  const latestNews = _.first(newsNodes);
  const item = {
    thumbnail: thumbnailUrl,
    name: collegeName,
    email,
  };
  return (
    <div className={cx(props.className)}>
      <Thumbnail item={item} />
      <ProfileNewsCard item={latestNews} className="news"/>
    </div>
  );
};

export default styled(ProfileNews)`
  margin: 20px 10px;
  display: flex;
  .thumbnail {
    @media (max-width: ${({ theme }) => theme.notDesktop}) {
      position: absolute;
      left: 0px;
      top: 10px;
      width: 200px;
      height: 220px;
      border: none;
      padding: 10px;
      margin: 0px;
      .name {
        font-size: 14px;
        line-height: 18px;
        color: ${({ theme }) => theme.primaryWhite};
        background: rgba(0, 0, 0, 0.3);
      }
      img {
        border-radius: 23px; 
        border: 1px solid grey;
        width: 140px;
        height: 140px;
      }
    }
    border: 1px solid grey;
    border-radius: 23px;
    width: 25%;
    max-width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 10px;
    img {
      object-fit: cover;
    }
    .name {
      font-weight: bold;
      font-size: 16px;
      line-height: 28px;
      text-align: center;
      margin: 8px 20px;
    }
    margin-right: 24px;
  }
  .news {
    flex: 1;
    margin: 0;
  }
  .contact {
    display: flex;
    align-items: center;
  }
`;

/* eslint-disable react/require-default-props */
import React from 'react';
import styled from 'styled-components';
import { Card, Button } from 'semantic-ui-react';
import cx from 'classnames';
import _ from 'lodash';

// import { getOneOf } from '../../lib/utils';

export const CatCard6 = props => {
  const { cat, onClick } = props;
  return (
    <Card className={cx(props.className, { isSelected: _.get(cat, 'isSelected') })} >
      <Card.Content id="type-q" className="content resFilterBtn" as={Button} onClick={onClick}>
        <div className="title text-p resFilterBtn">
          {_.get(cat, 'name')}
        </div>
      </Card.Content>
    </Card>
  );
};

export default styled(CatCard6)`
  .content {
    padding: 4px 10px !important;
    &#type-q {
      border-radius: 20px !important;
    }
    .title {
      text-align: center;
      margin-bottom: 0px;
      font-weight: 600 !important;
    }
    width: 100%;
  }
  box-shadow: none !important;

  height: 100%;
  position: relative;
  &:before {
    pointer-events: none;
    content: '';
    position: absolute;
    top: -3px;
    left: -3px;
    bottom: -3px;
    right: -3px;
    background: transparent;
    border-radius: 27px;
  }
  ${[
    { name: 'type-1', color: '#C27D3D' },
    { name: 'type-2', color: '#005F64' },
    { name: 'type-3', color: '#C3AE4F' },
    { name: 'type-4', color: '#CD5B49' },
  ].map(({ name, color }) => `
    &.${name} {
      .content {
        background: transparent;
      }
      .title {
        color: ${color};
      }
      &:before {
        border: 3px solid ${color};
      }
      &.isSelected {
        .content {
          background: ${color};
        }
        .title {
          color: white;
        }
      }  
    }  
  `).join('\n')}
`;

/* eslint-disable react/require-default-props */
import React from 'react';
import styled from 'styled-components';
import { Tab, Dimmer, Image, Segment } from 'semantic-ui-react';
import cx from 'classnames';
import _ from 'lodash';
import { useFormikContext, useFormik, FormikProvider } from 'formik';
import axios from 'axios';
import * as Yup from 'yup';

import Banner2 from './Banner2';
import CatList6 from './CatList6';
import CatList3 from './CatList3';
import Loading from '../Loading';
import { getOneOf } from '../../lib/utils';
import PageContext from '../PageContext';

const StyledPane = styled(Tab.Pane)`
  border: none !important;
  box-shadow: none !important;
  padding: 0px !important;
`;


const createIndex = (data, fields, { meta, filterIds }) => {
  const airNodes = [];
  (data || []).map(({ node }) => {
    const { data, ...rest } = node;
    if(filterIds && !filterIds.includes(rest.recordId)) return null;

    airNodes.push({
      ...rest,
      ...data,
      catMeta: _.find(_.get(meta, 'frontmatter.categories', []), { name: rest.table }),
    });
    return null;
  });
  const rtn = {};
  const idIndexes = {};
  airNodes.forEach(node => {
    fields.forEach(field => {
      const keys = _.get(node, field);
      rtn[field] = rtn[field] ? rtn[field] : {};
      const fieldIndexes = rtn[field];
      _.castArray(keys || []).forEach(key => {
        if(key) {
          if(!_.has(fieldIndexes, [key])) {
            _.set(fieldIndexes, [key], []);
          }
          fieldIndexes[key].push(node.id);
        }
      });
    })
    idIndexes[node.id] = node;
  });
  // add id indexes
  rtn.id = idIndexes;
  return rtn;
};

const LoadingDimmer = styled(({ className }) => (
  <Dimmer.Dimmable className={className} as={Segment} blurring dimmed={true}>
    <Dimmer active={true} inverted onClickOutside={() => {}} />
    <p>
      <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
    </p>
    <p>
      <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
    </p>
    <p>
      <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
    </p>
    <p>
      <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
    </p>
  </Dimmer.Dimmable>
))`
  margin: 24px 0px !important;
  border-radius: 20px !important;
`;

const LoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom:0;
  background: grey;
  opacity: 0.05;
`;

const ProfileResourceList = ({ cat, indexes, catType }) => {
  const filterForm = useFormikContext();
  const res = React.useMemo(() => {
    const tosFilters = [];
    _.map(filterForm.values.tosFilter, (value, tos) => {
      if(value) {
        tosFilters.push(tos);
      }
    });
    let ids = [];
    if(tosFilters.length) {
      tosFilters.forEach(tos => {
        ids = _.uniq([
          ...ids,
          ..._.castArray(_.get(indexes, ['Type_of_Service', tos]) || [])
        ]);
      });
    } else {
      ids = _.keys(getOneOf(indexes, 'id', {}));
    }
    return ids.map(id => _.get(indexes, `id.${id}`));
  }, [JSON.stringify([filterForm.values, indexes])]);



  const onClickTos = (tos) => {
    const newVal = { ...filterForm.values.tosFilter, [tos.name]: !filterForm.values.tosFilter[tos.name] };
    filterForm.setValues({ ...filterForm.values, tosFilter: newVal });
  };

  return (
    <>
    <Banner2 item={cat} renderFilter={() => (
      <CatList6
        cats={_.keys(getOneOf(indexes, 'Type_of_Service', {})).map(tos => {
          return {
            name: tos,
            isSelected: !!filterForm.values.tosFilter[tos],
          };
        })}
        catType={catType}
        onClick={onClickTos}
      />
    )} />
    <div style={{position: 'relative'}}>
      {
        !filterForm.isSubmitting ?
        <CatList3 cats={res} /> :
        <>
          <LoadingDimmer />
          <LoadingWrapper /><Loading />
        </>
      }
    </div>
    </>
  )
}

const ResourcePane = ({ cat, catType }) => {
  const filterForm = useFormik({
    initialValues: {
      zipcode: '',
      ids: null,
      tosFilter: {},
    },
    validateOnMount: true,
    validationSchema: Yup.object().shape({ zipcode: Yup.string().length(5)}),
    onSubmit: async (values) => {
      // call api to return resource
      let ids = null;
      if(values.zipcode) {
        const res = await axios.post(process.env.GATSBY_ZIPCODE_FILTER_ENDPOINT, { zipcode: values.zipcode });
        ids = [
          ..._.get(res, 'data.ids', []),
        ];
      }
      filterForm.setSubmitting(false);
      filterForm.setValues({ ...filterForm.values, ids });          
    },
  });
  const pageDataContext = React.useContext(PageContext);
  const data = _.get(pageDataContext, 'data', {});
  const resourceNodes = _.filter(_.get(data, 'resources.edges', []), (item) => _.get(item, 'node.table') === _.get(cat, 'name'));
  const indexes = createIndex(
    resourceNodes,
    ['Type_of_Service'],
    {
      meta: _.get(data, 'markdownRemark'),
      filterIds: filterForm.values.ids,
    },
  );

  return (
    <StyledPane attached={false}>
      <FormikProvider value={filterForm} >
        <ProfileResourceList cat={cat} indexes={indexes} catType={catType}/>
      </FormikProvider>
    </StyledPane>
  );
};

export const CatList5 = props => {
  const { cats } = props;
  const panes = _.castArray(cats).map((cat, index) => {
    const catType = _.get(['type-1', 'type-2', 'type-3', 'type-4', 'type-5'], index % 4);
    return {
      menuItem: cat.name,
      render: () => <ResourcePane cat={cat} catType={catType}/>,
    }
  });
  return (
    <div className={cx(props.className)}>
      <Tab menu={{ secondary: true }} panes={panes} />      
    </div>
  )
};

export default styled(CatList5)`
  @media (max-width: ${({ theme }) => theme.mobile}) {
    padding: 0 15px !important;
  }
  > .title {
    font-weight: 700;
    padding-left: 10px;
    margin-top: 1.2rem;
    margin-bottom: 0rem;
    @media (max-width: ${({ theme }) => theme.mobile}) {
      /* display: none; */
    }  
  }
  .ui.grid > .col.column.grid {
    @media (max-width: ${({ theme }) => theme.mobile}) {
      width: 100% !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .card {
      width: 100%;
      margin-bottom: 5px !important;
      margin-top: 0 !important;
    }
    &:first-child {
      .card {
        @media (max-width: ${({ theme }) => theme.mobile}) {
          margin-top: 15px !important;
        }
      }
    }
  }

  .ui.secondary.menu {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 20px;
    .item {
      text-align: center;
      justify-content: center;
      border-radius: 0px !important;
      font-size: 30px;
      font-weight: 500;
      color: ${({ theme }) => theme.primaryBlack};
      &.active {
        border-bottom: 1px solid ${({ theme }) => theme.txtPink};
        color: ${({ theme }) => theme.txtPink};
        background: transparent;
      }
      &:hover {
        background: transparent;
      }
    }
  }

`;

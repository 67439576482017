import React from 'react';
import { Card, Button, Grid } from 'semantic-ui-react';
import styled from 'styled-components';
import cx from 'classnames';
import _ from 'lodash';

const Option = styled(props => {
  const { option, onClick } = props;
  return (
    <Card className={cx(props.className, { isSelected: _.get(option, 'isSelected') })} >
      <Card.Content id="type-q" className="content resFilterBtn" as={Button} onClick={onClick} type="button">
        <div className="title text-h4 resFilterBtn">
          {_.get(option, 'name')}
        </div>
      </Card.Content>
    </Card>
  );
})`
  .content {
    padding: 20px 10px;
    &#type-q {
      border-radius: 23px !important;
    }
    .title {
      text-align: center;
      margin-bottom: 0px;
      font-weight: 600 !important;
    }
    width: 100%;
  }
  box-shadow: none !important;

  height: 100%;
  position: relative;
  &:before {
    pointer-events: none;
    content: '';
    position: absolute;
    top: -3px;
    left: -3px;
    bottom: -3px;
    right: -3px;
    background: transparent;
    border-radius: 27px;
  }
  ${[
    { name: 'type-1', color: '#C27D3D' },
    { name: 'type-2', color: '#005F64' },
    { name: 'type-3', color: '#C3AE4F' },
    { name: 'type-4', color: '#CD5B49' },
  ].map(({ name, color }) => `
    &.${name} {
      .content {
        background: transparent;
      }
      .title {
        color: ${color};
      }
      &:before {
        border: 3px solid ${color};
      }
      &.isSelected {
        .content {
          background: ${color};
        }
        .title {
          color: white;
        }
      }  
    }  
  `).join('\n')}
`

export const SelectInput = props => {
  const { options, onClick, label, value } = props;
  return (
    <div className={cx(props.className)}>
      {!!_.get(options, 'length') && <div className="label text-h3 txt-color-3">{label}</div>}
      <Grid >
        {
          options.map((option, index) => {
            const catType = _.get(['type-1', 'type-2', 'type-3', 'type-4', 'type-5'], index % 4);
            const isSelected = (_.get(option, 'value') === value);
            return (
              <Grid.Column key={option.name} mobile={4} tablet={4} computer={4} className="col grid">
                <Option
                  option={{
                    ...option,
                    isSelected,
                  }}
                  className={catType} onClick={() => onClick && onClick(option)}
                />
              </Grid.Column>
            )
          })
        }
      </Grid>

    </div>
  )
};

export default styled(SelectInput)`
  @media (max-width: ${({ theme }) => theme.mobile}) {
    padding: 0 15px !important;
  }
  > .label {
    font-weight: 500;
    margin-bottom: 6px;
  }
  .ui.grid > .col.column.grid {
    @media (max-width: ${({ theme }) => theme.mobile}) {
      width: 100% !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .card {
      width: 100%;
      margin-bottom: 5px !important;
      margin-top: 0 !important;
    }
    &:first-child {
      .card {
        @media (max-width: ${({ theme }) => theme.mobile}) {
          margin-top: 15px !important;
        }
      }
    }
  }

`;

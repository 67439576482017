/* eslint-disable react/require-default-props */
import React from 'react';
import styled from 'styled-components';
import { Button, Image } from 'semantic-ui-react';
import cx from 'classnames';
import _ from 'lodash';
import { useLocation } from '@reach/router';
import MaskedInput from 'react-text-mask'
import { useFormikContext } from 'formik';
import { useStaticQuery, graphql } from 'gatsby';

import { getOneOf } from '../../lib/utils';
import Breadcrumbs from '../Breadcrumbs';
import renderPara from '../../lib/renderPara';
import { useMetaOverride } from '../../lib/hoc/useMeta';

const typeToColor = {
  'type-1': 'orange',
  'type-2': 'teal',
  'type-3': 'yellow',
  'type-4': 'pink',
  'type-5': '',
  'type-6': 'pink',
}

const Thumbnail = ({ item }) => {
  const thumbnail = getOneOf(item, ['thumbnail.publicURL', 'thumbnail']);
  const thumbnailUrl = _.get(item, 'thumbnailUrl');
  const thumbnailAlt = _.get(item, 'thumbnailAlt');
  const thumbnailTarget = _.get(item, 'thumbnailTarget');
  const thumbProps = {
    ...(thumbnailUrl ? { as: 'a', href: thumbnailUrl } : {}),
    ...(thumbnailTarget ? { target: thumbnailTarget } : {}),
    ...(thumbnailAlt ? { alt: thumbnailAlt } : {}),
  }
  return (
    <Image
      src={thumbnail}
      {...thumbProps}
    />
  )
}

export const Banner2 = props => {
  const { item, paths, renderFilter } = props;
  const thumbnail = getOneOf(item, ['thumbnail.publicURL', 'thumbnail']);
  const className = getOneOf(item, ['className'], 'type-5');
  const actionText = getOneOf(item, 'actionText', 'BUTTON');
  const actionLink = getOneOf(item, 'actionLink', '');
  const actionProps = {
    as: actionLink ? 'a' : Button,
    ...(actionLink ? { href: actionLink } : {}),
  };

  const location = useLocation();
  const filterForm = useFormikContext();

  const metaData = useStaticQuery(graphql`
    query MetaDataQuery {
      metaMarkDown: markdownRemark(fields: { slug: { eq: "/meta/"}}) {        
        frontmatter {
          zipcodeSearchCategories
        }
      }
    }
  `);
  const metaPost = useMetaOverride('meta', metaData.metaMarkDown);

  const zipcodeSearchCategories = _.castArray(_.get(metaPost, 'frontmatter.zipcodeSearchCategories') || []);
  const showFilter = _.some(zipcodeSearchCategories, catPage => _.trim(location.pathname, '/').endsWith(_.trim(catPage, '/')));

  return (
    <div className={cx(props.className, className)}>
      <div className="left">
        {
          paths && 
          <Breadcrumbs className={"breadcrumbs text-small"} paths={paths} />
        }

        <div className="title text-hx">
          {renderPara(_.get(item, 'title'))}
        </div>
        <div className="descirption text-p">
          {renderPara(_.get(item, 'description'))}
        </div>
        <Button
          className="button text-p" style={{ display: 'none' }}
          {...(_.get(typeToColor, className) && { color: _.get(typeToColor, className) }) }
          inverted
          {...actionProps}
        >{actionText}</Button>
        {
          showFilter &&
          <div className="filter zipcodeFilterForm">
            <label>Filter based on your zip code:</label>
            <div>
              <form onSubmit={filterForm.handleSubmit}>
                <div className="zipcodeInput">
                  <MaskedInput
                    mask={[/\d/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
                    name="zipcode"
                    guide={false}
                    value={filterForm.values.zipcode}
                    onChange={filterForm.handleChange}
                    onBlur={filterForm.handleBlur}
                    className={cx("input", className)}
                  />
                  <Button
                    className="btn-times"
                    type="button"
                    disabled={!filterForm.values.zipcode}
                    onClick={() => {
                      filterForm.setFieldValue('zipcode', '');
                      filterForm.handleSubmit();
                    }}
                  ><span aria-hidden="true">&times;</span></Button>
                </div>
                <Button
                  className={cx("button text-p btn-submit", className)}
                  type="submit"
                  disabled={!filterForm.values.zipcode || filterForm.isSubmitting || !filterForm.isValid}
                >Filter</Button>
              </form>
            </div>
          </div>
        }
        {
          _.isFunction(renderFilter) ? renderFilter() : null
        }
      </div>
      {
        thumbnail && (
          <div className="thumbnail">
            <Thumbnail item={item} />
          </div>
        )
      }
    </div>
  );
};

export default styled(Banner2)`
  position: relative;
  background: ${props => props.theme.bgTeal};
  border-radius: 23px;
  padding: 40px 30px;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    padding: 20px 15px !important;
  }
  .left {
    max-width: 80%;
    padding-right: 20px;
    @media (max-width: ${({ theme }) => theme.mobile}) {
      max-width: 100%;
    }
  }
  margin: 0 10px;
  .thumbnail {
    position: absolute;
    right: 20px;
    top: 0;
    bottom: 0;
    height: 100%;
    max-width: 20%;
    display: flex;
    justfity-content: center;
    align-items: center;
    @media (max-width: ${({ theme }) => theme.mobile}) {
      display: none !important;
    }
  }
  .filter {
    label {
      font-weight: bold;
      font-size: 16px;
      line-height: 28px;
    }
    input {
      border-radius: 23px;
      padding: 8px 32px 8px 12px;
      margin-right: 8px;
      min-width: 240px;
      @media (max-width: ${({ theme }) => theme.mobile}) {
        width: 160px !important;
        max-width: 160px !important;
      }
      ${props => [
        { name: 'type-1', color: props.theme.txtOrange, bg: props.theme.bg1 },
        { name: 'type-2', color: props.theme.txtTeal, bg: props.theme.bg1 },
        { name: 'type-3', color: props.theme.txtYellow, bg: props.theme.bg1 },
        { name: 'type-4', color: props.theme.txtPink, bg: props.theme.bg1 },
      ].map(({ name, color }) => `
        &.${name} {
          border: 2px solid ${color};
          color: ${color} !important;
        }
      `).join('\n')}
      font-size: 18px;
    }
    .zipcodeInput {
      display: inline-block;
      position: relative;
    }
    .input {
      z-index: 1;
    }
    .btn-times {
      width: 20px;
      position: absolute;
      right: 0px;
      top: 0px;
      bottom: 0px;
      margin-right: 12px;
      background: none !important;
      border: none !important;
      z-index: 10;
      font-size: 20px;
    }
    .btn-submit, .btn-submit:hover, .btn-submit:focus {
      color: white;
      padding: 6px 28px !important;
      @media (max-width: ${({ theme }) => theme.mobile}) {
        margin: 10px auto;
      }
      ${props => [
        { name: 'type-1', color: props.theme.txtOrange, bg: props.theme.bg1 },
        { name: 'type-2', color: props.theme.txtTeal, bg: props.theme.bg1 },
        { name: 'type-3', color: props.theme.txtYellow, bg: props.theme.bg1 },
        { name: 'type-4', color: props.theme.txtPink, bg: props.theme.bg1 },
      ].map(({ name, color }) => `
        &.${name} {
          background: ${color};
        }
      `).join('\n')}
    }
  }
  .title {
    color: inherit;
  }
  .descirption {
    color: inherit;
  }
  .button {
    color: inherit;
    padding: 2px 20px;
    font-weight: 300;
  }
  ${props => [
    { name: 'type-1', color: props.theme.txtOrange, bg: props.theme.bg1 },
    { name: 'type-2', color: props.theme.txtTeal, bg: props.theme.bg1 },
    { name: 'type-3', color: props.theme.txtYellow, bg: props.theme.bg1 },
    { name: 'type-4', color: props.theme.txtPink, bg: props.theme.bg1 },
    { name: 'type-6', color: props.theme.txtPink, bg: 'rgba(250, 236, 209, 0.45)' },
  ].map(({ name, color, bg }) => `
    &.${name} {
      background: ${bg} !important;
      color: ${color};
      .button {
        border: 0.5px solid ${color};
      }
    }
  `).join('\n')}
  &.type-5 {
    background: ${props => props.theme.bgTeal} !important;
    color: ${props => props.theme.primaryWhite};
  }
`;

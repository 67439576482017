/* eslint-disable react/require-default-props */
import React from 'react';
import styled from 'styled-components';
import { Image } from 'semantic-ui-react';
import cx from 'classnames';
import _ from 'lodash';

import { getOneOf } from '../../lib/utils';
import PageContext from '../PageContext';

const Thumbnail = ({ item }) => {
  const thumbnail = getOneOf(item, ['thumbnail.publicURL', 'thumbnail']);
  const thumbnailUrl = _.get(item, 'thumbnailUrl');
  const thumbnailAlt = _.get(item, 'thumbnailAlt');
  const thumbnailTarget = _.get(item, 'thumbnailTarget');
  const thumbProps = {
    ...(thumbnailUrl ? { as: 'a', href: thumbnailUrl } : {}),
    ...(thumbnailTarget ? { target: thumbnailTarget } : {}),
    ...(thumbnailAlt ? { alt: thumbnailAlt } : {}),
  }
  return (
    <Image
      src={thumbnail}
      {...thumbProps}
      className="thumbnail"
    />
  )
}

export const ProfileCover = props => {
  const pageDataContext = React.useContext(PageContext);
  const thumbnailUrl = _.get(pageDataContext, 'data.organization.data.Profile_Pic.0.url');
  const item = {
    thumbnail: thumbnailUrl,
  }

  const thumbnail = getOneOf(item, ['thumbnail.publicURL', 'thumbnail']);

  return (
    !!thumbnail &&
    <div className={cx(props.className)}>
      <Thumbnail item={item} />
    </div>
  );
};

export default styled(ProfileCover)`
  margin: 0 10px;
  .thumbnail {
    border: ${props => props.theme.bgTeal};
    border-radius: 23px;
    width: 100%;
    object-fit: cover;
    min-height: 220px;
  }
`;

import React from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components';
import { Container } from 'semantic-ui-react';
import _ from 'lodash';
import { useStaticQuery, graphql } from 'gatsby';

import CatList5 from './CatList/CatList5';
import ProfileCover from './CatList/ProfileCover';
import ProfileNews from './CatList/ProfileNews';
import { getOneOf } from '../lib/utils';

const StyledContainer = styled(Container)`
  min-height: 40vh;
  margin-bottom: 40px;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    margin-top: 20px;
  }
`;

export const ProfilePageTemplate = ({ post, isPreview, pageContext }) => {
  const metaData = useStaticQuery(graphql`
    query ProfileMetaDataQuery {
      metaMarkDown: markdownRemark(fields: { slug: { eq: "/meta/"}}) {        
        frontmatter {
          zipcodeSearchCategories
        }
      }
    }
  `);
  return (
    <div>
      <Helmet>
        <title>BestFit</title>
        <link rel="canonical" href="https://best-fit.app/" />
      </Helmet>
      <section>
        <StyledContainer>
          <div style={{ position: 'relative' }}>
            <ProfileCover
              item={_.get(post, 'frontmatter')}
              metaData={metaData}
            />
            <ProfileNews
              item={_.get(post, 'frontmatter')}
              metaData={metaData}
            />
          </div>
          <CatList5 cats={getOneOf(post, 'frontmatter.categories').map(cat => {
            return {
              ...cat,
              slug: `${pageContext.locPath}${_.trimStart(cat.slug, '/')}`
            };
          })} />
        </StyledContainer>
      </section>
    </div>
  )
}


export default ProfilePageTemplate;

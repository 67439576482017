import React from 'react';
import _ from 'lodash';
import { Button, Modal, Header, Icon, TextArea } from 'semantic-ui-react';
import styled from 'styled-components';
import { useFormik } from 'formik';
import axios from 'axios';
import * as Yup from 'yup';
import cx from 'classnames';

import SelectInput from '../SelectInput';
import PageContext from '../PageContext';

const ContactHeader = styled(Header)`
  color: #CD5B49 !important;
  text-align: center;
  margin-bottom: .5em !important;
  font-size: 32px !important;
  margin-top: 31px !important;
`;

export const ContactForm = styled(({
  trigger = (
    <Button as="a" color="pink" className="contact">
      <Icon name="mail outline" size="large"></Icon>Contact
    </Button>
  ),
  className,
}) => {
  const pageDataContext = React.useContext(PageContext);
  const templateOptions = _.castArray(_.get(pageDataContext, 'data.contactTemplates.nodes', [])).map(node => {
    const name = _.get(node, 'data.Name');
    return {
      name,
      value: name,
    };
  });
  const organizataionName = _.get(pageDataContext, 'data.organization.data.Organization_Name');
  const [isSubmitted, $isSubmitted] = React.useState(false);
  const contactForm = useFormik({
    initialValues: {
      studentName: '',
      email: '',
      contactTemplate: '',
      notes: '',
      organizataionName,
    },
    validateOnMount: true,
    validationSchema: Yup.object().shape({
      studentName: Yup.string().required(),
      email: Yup.string().email().required(),
      contactTemplate: Yup.string().test(function (value) {
        const { notes } = this.parent;
        if (!notes) return value != null
        return true;
      }),
      notes: Yup.string().test(function (value) {
        const { contactTemplate } = this.parent;
        if (!contactTemplate) return value != null
        return true;
      })
    }),
    onSubmit: async (values) => {
      // call api to return resource
      if(contactForm.isValid) {
        const res = await axios.post(process.env.GATSBY_CONTACT_FORM_ENDPOINT, {
          ...values,
        });
      }
      contactForm.setSubmitting(false);
      $isSubmitted(true);
    },
  });

  return (
    <Modal
      trigger={trigger}
      onClose={() => $isSubmitted(false)}
    >
      <Modal.Content>
        <ContactHeader>Contact Us</ContactHeader>
        <div className={className}>
          {
            isSubmitted ?
            <div className="header">
              A contact email has been sent to school staff
            </div> :
            <>
            <div className="header">
              Complete this form & a member of our team will reach out shortly!
            </div>
              <form onSubmit={contactForm.handleSubmit}>
                <div className="studentName">
                  <div className="title text-h3 txt-color-3">Your name</div>
                  <input
                    name="studentName"
                    value={contactForm.values.studentName}
                    onChange={contactForm.handleChange}
                    onBlur={contactForm.handleBlur}
                    className={cx("input")}
                    placeholder="Your name"
                  />
                </div>
                <div className="inputGroup studentName">
                  <div className="title text-h3 txt-color-3">School Email Address<span>*</span></div>
                  <input
                    name="email"
                    value={contactForm.values.email}
                    onChange={contactForm.handleChange}
                    onBlur={contactForm.handleBlur}
                    className={cx("input")}
                    placeholder="Your email"
                  />
                </div>
                <div className="title text-h3 txt-color-3">How can we help?<span>*</span></div>
                <div className="inputGroup">
                  <SelectInput
                    name="contactTemplate"
                    value={contactForm.values.contactTemplate}
                    options={templateOptions}
                    label={'Use one of our templates'}
                    onClick={({ value }) => {
                      contactForm.setFieldValue('contactTemplate', value);
                    }}
                  />
                </div>
                <div className="inputGroup">
                  <div className="title text-h3 txt-color-3">Or leave a custom message:</div>
                  <TextArea
                    name="notes"
                    value={contactForm.values.notes}
                    onChange={contactForm.handleChange}
                    onBlur={contactForm.handleBlur}
                    className={cx("input")}
                    placeholder="Your custom message"
                  />
                </div>
                <div className="row-submit">
                  <Button
                    className={cx("button text-p btn-submit type-4")}
                    type="submit"
                    disabled={contactForm.isSubmitting || !contactForm.isValid}
                  >Send</Button>
                </div>
              </form>
            </>
          }
        </div>
      </Modal.Content>
    </Modal>
  );
})`
  max-width: 600px;
  margin: 0 auto;
  .inputGroup {
    margin: 8px 0;
  }
  .header {
    text-align: center;
    margin-bottom: 20px;
  }
  .title {
    font-weight: 500;
  }
  .input {
    border-radius: 20px !important;
    padding: 8px;
    width: 100%;
    border-width: 1px !important;
  }
  .btn-submit, .btn-submit:hover, .btn-submit:focus {
    color: white;
    padding: 12px 52px !important;
    @media (max-width: ${({ theme }) => theme.mobile}) {
      margin: 10px auto;
    }
    ${props => [
      { name: 'type-1', color: props.theme.txtOrange, bg: props.theme.bg1 },
      { name: 'type-2', color: props.theme.txtTeal, bg: props.theme.bg1 },
      { name: 'type-3', color: props.theme.txtYellow, bg: props.theme.bg1 },
      { name: 'type-4', color: props.theme.txtPink, bg: props.theme.bg1 },
    ].map(({ name, color }) => `
      &.${name} {
        background: ${color};
      }
    `).join('\n')}
  }

  .row-submit {
    text-align: center;
  }
`;

export default ContactForm;

/* eslint-disable react/require-default-props */
import React from 'react';
import styled from 'styled-components';
import { Grid } from 'semantic-ui-react';
import cx from 'classnames';
import _ from 'lodash';

import CatCard6 from './CatCard6';

export const CatList6 = props => {
  const { cats, onClick, catType } = props;
  return (
    <div className={cx(props.className)}>
      {!!_.get(cats, 'length') && <div className="title text-h6 txt-color-3">View resources by type:</div>}
      <Grid >
        {
          cats.map((cat) => {
            return (
              <Grid.Column key={cat.name} mobile={8} tablet={6} computer={4} className="col grid">
                <CatCard6 cat={cat} className={catType} onClick={() => onClick(cat)}/>
              </Grid.Column>
            )
          })
        }
      </Grid>

    </div>
  )
};

export default styled(CatList6)`
  @media (max-width: ${({ theme }) => theme.mobile}) {
    padding: 0 15px !important;
  }
  > .title {
    font-weight: 600;
    margin-bottom: 0px;
    padding-top: 10px;
  }
  .ui.grid > .col.column.grid {
    @media (max-width: ${({ theme }) => theme.mobile}) {
      width: 100% !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .card {
      width: 100%;
      margin-bottom: 5px !important;
      margin-top: 0 !important;
    }
    &:first-child {
      .card {
        @media (max-width: ${({ theme }) => theme.mobile}) {
          margin-top: 15px !important;
        }
      }
    }
  }

`;

import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import _ from 'lodash'

import HomeLayout from '../components/HomeLayout';
import PageContext from '../components/PageContext';

import { HTMLContent } from '../components/Content'

import ProfilePageTemplate from '../components/ProfilePageTemplate'
import { useMetaOverride } from '../lib/hoc/useMeta';

import '../graphql/allFragments';

const ProfilePage = ({ data, pageContext }) => {
  const { markdownRemark: defaultPost } = data;
  const post = useMetaOverride('meta', defaultPost);

  const locMeta = _.find(_.castArray(_.get(post, 'frontmatter.areas')), { slug: pageContext.locPath });
  return (
    <PageContext.Provider value={{ data, pageContext }}>
      <HomeLayout headerText={_.get(post, 'frontmatter.description')}>
        <ProfilePageTemplate
          contentComponent={HTMLContent}
          title={post.frontmatter.title}
          content={post.html}
          post={{
            frontmatter: {
              ...post.frontmatter,
              ...locMeta,
            },
          }}
          pageContext={pageContext}
        />
      </HomeLayout>
    </PageContext.Provider>
  )
}

ProfilePage.propTypes = {
  data: PropTypes.object.isRequired,
};

export const homePageQuery = graphql`
  query ProfilePage($locRegex: String, $recordId: String) {
    markdownRemark(fields: { slug: { eq: "/meta/"}}) {
      html
      frontmatter {
        title
        description
        areas {
          title
          description
          slug
          actionText
          actionLink
        }
        categories {
          name
          title
          description
          className
          thumbnail {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
            publicURL
          }
          slug
          airtable
        }
      }
    }
    allAirtable(filter: { data: { Area: { regex: $locRegex }}}) {
      edges {
        node {
          ...resourceFragment
        }
      }
    }
    resources: allAirtable(filter: {
      data: {
        in_Service_: { eq: "true" },
        Organization: {in: [$recordId] }
      }
    }) {
      edges {
        node {
          ...resourceFragment
        }
      }
    }    
    organization: airtable(recordId: {eq: $recordId}) {
      ...organizationFragment
    }
    news: allAirtable(
      filter: {
        table: {eq: "News"},
        data: {
          status: {eq: "published"}
          Organization: {in: [$recordId] }
        }
      }
      sort: {order: DESC, fields: data___date_scheduled}
    ) {
      nodes {
        ...newsFragment
      }
    }
    contactTemplates: allAirtable(
      filter: {
        table: {eq: "ContactTemplates"},
        data: {
          Organization: {in: [$recordId] }
        }
      }
    ) {
      nodes {
        ...contactTemplateFragment
      }
    }
  }
`;

export default ProfilePage;
